import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ParticipatePage = () => (
  <Layout>
    <SEO
      title="Comment participer ? Cinélangues"
      description="Cinélangues poursuit, depuis de nombreuses années maintenant, toujours avec autant de passion et de conviction, son action de diffusion des cinématographies."
      pathname="/a-propos-participer/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-md-8 header-content my-auto">
            <h1>
              <i className="fal fa-camera-movie ml-2 mr-2"></i>Participer
            </h1>
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="row">
          <div className="card card-orange col-lg-6">
            <div className="card-body">
              <h3 className="mt-0">
                Cinélangues joue le rôle d’interface entre le monde du cinéma et le
                monde de l’éducation, en s’appuyant sur des axes forts tels que
                l’ouverture aux cinématographies étrangères, la diversité culturelle,
                et la pluralité linguistique.
              </h3>
              <p>
                <strong>
                  Nos partenaires sont à la fois des cinémas indépendants ayant à
                  coeur l’éducation au cinéma des jeunes, des distributeurs désireux
                  de faire découvrir au public français du cinéma d’auteur, des
                  professeurs, des documentalistes souhaitant intégrer le cinéma dans
                  leur enseignement et des établissements scolaires menant des
                  projets Langues et cinéma.
                </strong>
              </p>
            </div>
          </div>
          <div className="card col-lg-6 card-illustration-7 d-none d-lg-block"></div>
        </div>
        <div className="row">
          <div className="card card-content col-lg-6">
            <div className="card-body">
              <h2 className="px-2">
                <i className="fa fa-film" />
                &nbsp;&nbsp;Cinémas
              </h2>
              <p className="px-2">
                Nous proposons aux salles de cinéma d’accueillir le dispositif
                Cinélangues en vous permettant d’élaborer votre propre programmation
                à partir de nos propositions. Nous pouvons vous aider à négocier les
                copies des films et vous fournissons les documents pédagogiques.
                Chaque salle partenaire est invitée à s’approprier le dispositif et à
                participer à son l’évolution.
              </p>
            </div>
          </div>
          <div className="card card-content col-lg-6">
            <div className="card-body">
              <h2 className="px-2">
                <i className="fa fa-user-edit" />
                &nbsp;&nbsp;Professeurs
              </h2>
              <p className="px-2">
                Vous souhaitez proposer des films qui vous semblent intéressants à
                exploiter en classe de langue, animer des séances scolaires, écrire
                des Avis Cinélangues, proposer l’exploitation cinématographique d’une
                séquence de film, faire connaître du matériel pédagogique, n’hésitez
                pas à prendre contact avec nous.
              </p>
            </div>
          </div>
          <div className="card card-content col-lg-6">
            <div className="card-body">
              <h2 className="px-2">
                <i className="fa fa-user-cowboy" />
                &nbsp;&nbsp;Distributeurs
              </h2>
              <p className="px-2">
                Vous pensez qu’un film de vos films peut intéresser le public
                scolaire d’un point de vue culturel et linguistique. N hésitez pas à
                nous le soumettre avec le maximum d’anticipation pour que nous
                puissions être votre partenaire lors de la sortie du film : organiser
                une avant-première pour les professeurs, intégrer le film à nos
                programmations.
              </p>
            </div>
          </div>
          <div className="card card-content col-lg-6">
            <div className="card-body">
              <h2 className="px-2">
                <i className="fa fa-user-shield" />
                &nbsp;&nbsp;Représentants d'établissement scolaire
              </h2>
              <p className="px-2">
                Vous souhaitez mettre en place un projet Langues et cinéma dans votre
                établissement, faciliter l’intégration du cinéma dans l’enseignement
                des langues, faire participer vos élèves à des événements
                cinématographiques, n’hésitez pas à nous contacter pour avoir des
                conseils et des informations.
              </p>
            </div>
          </div>
          <div className="card card-content col-lg-6">
            <div className="card-body">
              <h2 className="px-2">
                <i className="fa fa-user-graduate" />
                &nbsp;&nbsp;Spécialistes de cinéma, historiens, sociologues
              </h2>
              <p className="px-2">
                Nous sommes toujours à la recherche d’intervenants pour accompagner
                nos séances de cinéma scolaire. Si vous êtes disponibles de temps à
                autre pour remplir cette mission, n’hésitez pas à vous faire
                connaître. Nous vous contacterons dès lors qu’un film de nos
                programmations relèvera de votre domaine de compétence
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ParticipatePage;
